
.buttonstyle{
	display: inline-block;
  	padding: 10px 16px 7px 16px;
  	border: 2px solid #64ffda;
  	border-radius: 6px;
  	font-size: 16px;
  	text-align: center;
  	color: custom.300;
  	cursor: pointer;
  	margin: 7px;
}

.buttonstyle:hover {
  background-color: #4A5568;
  color: #4A5568;
}
