@import url('https://fonts.cdnfonts.com/css/calibre');
@import url('https://fonts.cdnfonts.com/css/ubuntu-mono');


.myButton {
  display: inline-block;
  padding: 8px 16px;
  border: 2px solid blue;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  background-color: white;
  color: blue;
  cursor: pointer;
}

.myButton:hover {
  background-color: blue;
  color: white;
}
